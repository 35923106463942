.mouse {
    cursor: url('../../assets/mouse.png'), auto;
  }
  
.home-cover{
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-cover img {
    height: auto;
    width: 100%;
}
.home-cover2 {
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
}
.home-cover2 img {
    width: 100%;
}
.home-container {
    padding: 15px 130px;
}
.home-container h1 {
    margin-bottom: 10px;
}
.home-container .courses {
    margin-bottom: 35px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.home-container .courses .card {
    background-color: #333;
    border: 3px solid white;
    border-radius: 15px;
    overflow: hidden;
}
.home-container .courses img {
    width: 100%;
    cursor: pointer;
}
.card .card-text{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0 20px 15px;
}
.card .card-text p,
.card .card-text p {
    margin: 0;
    font-size: 15px;
}
.home-content {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
    height: 100%;
}
.touch {
    width: 100%;
}
.touch input {
    width: 100%;
    height: 165px;
    background-color: #333;
    color: white;
    box-shadow: none;
    border: none;
    border-radius: 15px;
}
.touch input::placeholder {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 15px;
    font-weight: 500;
    padding: 5px;
}
.file{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}
.file .addFile {
    display: flex;
    flex-direction: column;
    font-weight: 100;
}
.file .addFile label {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #7b7a7a;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
    margin-bottom: 10px;
}
.file .btn button {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #e1d33e;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}
#file-input {
    width: auto;
    height: auto;
    background-color: inherit;
    border-radius: 0;
    cursor: pointer;
}
.faq {
    height: 100%;
}
.wrapper {
    display: flex;
    margin-top: -60px;
}
.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.social-icons .icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
@media (max-width:1092px) {
.home-cover img {
    height: auto;
}
.home-container {
    padding: 2rem;
}
.home-container h1 {
    font-size: 20px;
}
.home-content {
    width: 100%;
    flex-direction: column;
}
.touch input {
    top: 0;
    left: 0;
}
.wrapper {
    margin-top: 0;
    justify-content: center;
    align-items: center;
}
.social-icons {
    gap: 30px;
}
.home-cover2 {
    margin: 0 30px ;
}
}
@media (max-width: 900px) {
    .home-container .courses {
        margin-top: 30px;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
}
@media (max-width: 550px) {
    .home-cover img {
        height: auto;
        width: 100%;
    }
    .home-container .featured {
        margin-bottom: -20px;
    }
    .home-container .courses {
        margin-top: 30px;
        grid-template-columns: 1fr;
        gap: 15px;
    }
    .card-text h3 {
        font-size: 15px;
    }
    .addFile {
        width: 70%;
    }
    .btn {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .btn button {
        width: 100%;
        height: 100%;
    }
    .wrapper {
        margin-top: 0;
        justify-content: center;
        align-items: center;
    }
    .social-icons {
        gap: 15px;
    }
    .home-cover2 img {
        width: 100%;
        height: auto;
    }
}
