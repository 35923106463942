.right {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.book .box {
  background-color: #1C1C1C;
  padding: 15px;
  border: 2px solid #e1d33e;
  border-radius: 10px;
  width: 300px;
  margin-bottom: 20px;
}
.book .box .text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.book .box .text p {
  margin: 5px 0;
  font-size: 18px;
}
.book .box h2,
.book .box p {
  margin-bottom: 5px;
}
.book .box h2 span {
  margin-left: -5px;
  font-size: 13px;
}
.book .box p span {
  text-decoration: line-through;
  margin-left: 6px;
}
.book .box .button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  gap: 10px;
}
.book .box .button button {
  width: 100%;
  background-color: #e1d33e;
  color: black;
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.book .box #chat {
  background-color: rgb(52, 116, 52);
  color: white;
  width: 150px;
  height: 50px;
}
.book .box .button #chat .whatsapp{
  width: 100%;
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(52, 116, 52);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.payment .paymentpopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}
.payment .paymentpopup .Popup-content {
  background-color: black;
  width: 600px;
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.payment .paymentpopup .Popup-content form .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.row label {
  width: 50%;
}
.payment .paymentpopup .Popup-content form label {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 100;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.payment .paymentpopup .Popup-content form label input {
  background-color: #333;
  color: white;
  border-radius: 10px;
  padding: 5px;
  border: none;
  margin: 10px 0;
  font-size: 15px;
}
form label input:valid {
  background-color: #333;
  color: white;
}
form select {
  border-radius: 10px;
  padding: 5px;
  border: none;
  margin: 10px 0;
  font-size: 15px;
}
.payment .paymentpopup .Popup-content .next {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.methods {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.methods img {
  width: 30px;
  height: 45px;
  border-radius: 50%;
}
.payment .paymentpopup .Popup-content button {
  background-color: #e1d33e;
  color: black;
  width: 140px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5px;
  cursor: pointer;
  border: none;
}
.payment .paymentpopup .Popup-content button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.close-icon {
  position: absolute;
  right: 27%;
  top: 17%;
  color: black;
  background-color: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .book .box {
    width: 250px;
    height: 100%;
  }
  .book .box .text h2 {
    font-size: 20px;
  }
  .book .box .text p {
    font-size: 13px;
  }
  .book .box .payment .button {
    display: flex;
    gap: 10px;
  }
  .book .box .payment .button button,
  .book .box .payment .button button a {
    font-size: 14px;
    font-weight: 600;
  }
  .payment .paymentpopup, 
  .payment .paymentPopup {
    padding: 0 10px;
  }
  .paymentpopup .Popup-content .close-icon {
    position: absolute;
    top: 10%;
    right: 10%;
  }
}
@media (max-width: 550px) {
  .payment .paymentpopup .Popup-content {
    width: 98%;
  }
  .paymentpopup .Popup-content .close-icon {
    top: 10%;
    right: 5%;
  }
}
