.interior {
  background-color: black;
  color: white;
}
.swiper-pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%, 50%);
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: black;
  border: 2px solid #fff;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
}
.swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1;
}
.mobile-info {
  display: none;
}
.section-container {
  padding: 0 150px;
}
.section-container .text h1 {
  font-size: 40px;
  font-weight: 650;
  margin-top: 15px;
}
.section-container .text p {
  margin: 15px 0;
}
.intro-image {
  margin: 0 20px;
}
.intro-image-container {
  padding: 10px 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-image img {
  padding-bottom: 20px;
  width: 100%;
  height: calc(100vh - 100px);
}
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: #333;
  --swiper-navigation-size: 100px;
  font-weight: bold;
}
.content {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  height: 500px;
}
.left {
  flex: 1;
}
.left .media {
  width: 100%;
  height: 100%;
}
.left .media .video {
  width: 100%;
  height: 100%;
}
video {
  width: 100%;
  height: 100%;
  border: 3px solid white;
  border-radius: 25px;
}
.right {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.book .info p {
  font-size: 15px;
  margin-top: 10px;
  display: flex;
  gap: 5px;
}
.book .info p .icons {
  margin-top: 1px;
}
#urban-mobile h2 {
  margin-bottom: 20px;
}
#urban-mobile .instructor-card {
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 25px;
}
#desc {
  background-color: #1c1c1c;
  display: flex;
  width: 50%;
  margin: 15px 0;
  border-radius: 25px;
  padding: 15px;
}
#desc p {
  font-size: 20px;
  font-weight: 100;
  line-height: 1.5;
}
#urban-mobile .instructor-card .instructor-info {
  padding: 20px;
  width: 80%;
}
#urban-mobile .instructor-card .instructor-info h4 {
  font-size: 20px;
  font-weight: 450;
  margin-bottom: 15px;
}
#urban-mobile .instructor-card .instructor-info p {
  font-size: 20px;
  font-weight: 100;
  line-height: 1.5;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  margin: 0;
}
#urban-mobile .instructor-card .image {
  width: 280px;
  height: 280px;
}
#urban-mobile .instructor-card img {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
}
#urban-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#urban-gallery img {
  width: 100%;
}
.Gallery {
  margin: 50px 0;
}
.Gallery .text h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}
.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 30px;
}
.gallery-card .image {
  position: relative;
}
.gallery-card .image img {
  height: 100%;
}
.album {
  position: absolute;
  font-weight: 500;
  font-size: 25px;
  color: white;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.card-text {
  margin-top: 10px;
  cursor: pointer;
}
.card-text h1 {
  font-size: 25px;
  font-weight: 200;
}
.gallery-popup .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  z-index: 1;
}
.gallery-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.gallery-popup .gallery-popup-content {
  width: 100%;
  padding: 15px;
  background-color: black;
  display: grid;
  grid-template-columns: repeat(4, 200px);
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.gallery-popup-content img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}
.slider-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.slider-close-icon {
  color: white;
  font-weight: bolder;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 50000;
}
.slider-popup-content {
  width: 100%;
  height: 100%;
}
.slider-popup .slider-popup-swiper {
  width: 100%;
  height: 100%;
}
.slider-popup .slider-popup-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-popup .slider-popup-image img {
  padding: 10px;
  scale: 28%;
}
.slider-popup-swiper .swiper-button-next,
.slider-popup-swiper .swiper-button-prev {
  color: #333;
  font-weight: bolder;
  --swiper-navigation-size: 80px;
}
.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 25px auto;
}
.footer .social,
.footer .course {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  font-size: 18px;
}
.footer .social a {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 3000px) {
  .gallery-card img {
    width: 100%;
    height: 600px;
    border: 3px solid white;
    border-radius: 15px;
    cursor: pointer;
  }
}
@media (max-width: 1920px) {
  .gallery-card img {
    height: 250px;
  }
}
@media (max-width: 1439px) {
  .instructor-card .instructor-info {
    padding: 10px;
  }
  .instructor-card .instructor-info h4 {
    margin-bottom: 10px;
  }
  .instructor-card .image {
    width: 500px;
    height: 200px;
  }
  .instructor-card img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
}
@media (max-width: 1024px) {
  #interior {
    display: none;
  }
  .intro-image .swiper-button-next,
  .intro-image .swiper-button-prev {
    display: none;
  }
  .intro-image {
    margin: 0;
  }
  .intro-image-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intro-image img {
    width: 100%;
    height: auto;
  }
  .section-container {
    padding: 2rem 8.5rem;
  }
  .section-container .text h1 {
    font-size: 22px;
  }
  .section-container .text p {
    font-size: 15px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
  .left .media video {
    height: 250px;
    margin-bottom: 10px;
  }
  .right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mobile-info {
    display: block;
    margin-bottom: 15px;
  }
  .mobile-info h3 {
    font-size: 19px;
  }
  .mobile-info p {
    font-size: 15px;
    margin-top: 10px;
    display: flex;
  }
  .mobile-info .icons {
    margin-right: 8px;
  }
  .info {
    display: none;
  }
  #urban-mobile {
    display: block;
  }
  #urban-mobile .instructor-card {
    display: flex;
    flex-direction: column;
  }
  #urban-mobile .instructor-card .instructor-info {
    width: 100%;
    padding: 15px;
  }
  #urban-mobile .instructor-card .instructor-info h4 {
    margin-bottom: 5px;
    font-size: 14px;
  }
  #urban-mobile .instructor-card .instructor-info p {
    font-size: 12px;
    line-height: 1.2;
  }
  #urban-mobile .instructor-card .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  #urban-mobile .instructor-card .image img {
    height: 200px;
    width: 200px;
  }
  #urban-gallery {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #urban-gallery img {
    width: 100%;
    height: auto;
  }
  #desc {
    width: 100%;
  }

  .Gallery {
    margin: 15px auto;
  }
  .Gallery .text h2 {
    font-size: 18px;
  }
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
  }
  .card-text h1 {
    font-size: 22px;
  }
  .gallery-popup .gallery-popup-content {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .gallery-popup .gallery-popup-content img {
    width: 100%;
    height: 130px;
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
  }
  .slider-popup-swiper .swiper-button-next,
  .slider-popup-swiper .swiper-button-prev {
    display: none;
  }
  .slider-popup .slider-popup-image {
    width: 100%;
    height: 100%;
  }
  .slider-popup .slider-popup-image img {
    width: 100%;
    scale: none;
  }
  .footer {
    margin: 50px 0 0 0;
  }
  .footer .social,
  .footer .course {
    margin: 0;
  }
  .footer a,
  .footer link {
    font-weight: 400;
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: 1fr;
  }
  .footer {
    flex-direction: column-reverse;
    margin: 0;
  }
  .footer .social,
  .footer .course {
    margin: 15px 0;
  }
  .footer a,
  .footer link {
    font-weight: 250;
  }
  .gallery-container {
    display: flex;
    flex-direction: column;
  }
  .gallery-card {
    width: 400px;
  }
  .gallery-card .image {
    height: 400px;
  }
  .gallery-card .image img {
    height: 100%;
  }
}
@media (max-width: 650px) {
  .instructors {
    margin-top: 0;
  }
  .section-container {
    padding: 1rem 2rem;
  }
  .section-container .text h1 {
    margin: 0;
  }
  .mySwiper .swiper-button-next,
  .mySwiper .swiper-button-prev {
    display: none;
  }
  .intro-image .intro-image-container img {
    height: auto;
  }
  .instructor-card {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 15px;
  }
  .instructor-card .instructor-info {
    padding: 5px;
    margin: 5px;
    margin-right: 0;
  }
  .instructor-card .instructor-info h4 {
    font-size: 14px;
    font-weight: 450;
    margin-bottom: 10px;
  }
  .instructor-card .instructor-info p {
    font-size: 16px;
    line-height: 1.2;
  }
  .instructor-card .image {
    width: 700px;
    height: 100%;
  }
  .instructor-card .image img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .card-text h1 {
    font-size: 16px;
    font-weight: 500;
  }
  #desc p {
    font-size: 17px;
    line-height: 1.2;
  }
}
@media (max-width: 400px) {
  .intro-image .intro-image-container img {
    height: auto;
  }
  .instructor-card .instructor-info h4 {
    margin-bottom: 2px;
    font-size: 14px;
  }
  .instructor-card .instructor-info p {
    font-size: 16px;
    line-height: 1.2;
  }
  .instructor-card .image {
    width: 700px;
    height: 160px;
  }
  .instructor-card .image img {
    width: 100%;
    height: 100%;
  }
  .gallery-popup .gallery-popup-content img {
    height: 80px;
  }
  .gallery-card {
    width: 300px;
  }
  .gallery-card .image {
    height: 300px;
  }
  .gallery-card .image img {
    height: 100%;
  }
}
@media (max-width: 350px) {
  .gallery-card {
    width: 280px;
  }
  .gallery-card .image {
    height: 280px;
  }
  .gallery-card .image img {
    height: 100%;
  }
}