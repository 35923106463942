* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.navbar {
    background-color: #1c1c1c;
    padding: 0 130px;
    height: 80px;
    display: flex;
    align-items: center;
    color: white;
    font-family: var(--font-family);
}
.navbar .mobile-navbar {
    display: flex;
    width: 100%;
    height: auto;
    justify-content:space-between;
    align-items: center;
}
.mobile-navbar .logo {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 130px;
    height: 130px;
}
.mobile-navbar .logo img {
    width: 100%;
    height: 100%;
}
.menu-icon, .close-icon {
    cursor: pointer;
}

.mobile-navbar .menu-content {
    width: 100%;
    height: auto;
    background-color: black;
    color: white;
    position: absolute;
    z-index: 9999;
    top: 80px;
    left: 0;
}
.mobile-navbar .menu-content .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
} 
.mobile-navbar .menu-content .menu-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction:column ;
    z-index: 9999;

}
.mobile-navbar .menu-content .menu-container .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    margin: 10px 0;
}
.mobile-navbar .menu-content .menu-container .btns button {
    background-color: inherit;
    color: #e1d33e;
    border: none;
    font-size: 20px;  
    font-weight: 200;  
}
.mobile-navbar .menu-content .menu-container .separator {
    height: 1px;
    width: 100%;
    background-color: #333;
    display: flex;
}
.mobile-navbar .menu-content .menu-container .menu-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
}
.menu-icons p {
    width: 50px;
    height: 50px;
    font-size: 40px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid white;
    border-radius: 10px;
    padding-bottom: 10px;
}
.btns .register-popup {
    position: absolute;
    background-color: #212121;
    top: 20px;
    border: 2px solid white;
    border-radius: 25px;
    width: 55%;
    z-index: 999;
}
#panel1a-header {
    background-color:black;
    color: white;
    
}
#heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: var(--font-family);
    font-size:20px;
    font-weight:500; 
}
@media (max-width: 1092px) {
    .navbar .mobile-navbar {
        display: flex;
        width: 100%;
        justify-content:space-between;
        align-items: center;
    }
    .mobile-navbar .logo {
        display: flex;
        justify-self: center;
        align-items: center;
        width: 100px;
        height: 100px;
    }
    .mobile-navbar .logo img {
        width: 100%;
        height: 100%;
    }
    .mobile-navbar .menu-content {
        width: 100%;
        height: auto;
        background-color: black;
        color: white;
        position: absolute;
        z-index: 999;
        top: 80px;
        padding: 1rem;
    }
    .mobile-navbar .menu-content .close-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
    } 
    .mobile-navbar .menu-content .menu-container {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        flex-direction:column ;
        z-index: 9999;

    }
    .mobile-navbar .menu-content .menu-container .btns {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 35px;
        margin: 10px 0;
    }
    .mobile-navbar .menu-content .menu-container .btns button {
        background-color: inherit;
        color: #e1d33e;
        border: none;
        font-size: 20px;  
        font-weight: 200;  
    }
    .mobile-navbar .menu-content .menu-container .separator {
        height: 1px;
        width: 60%;
        background-color: #333;
        display: flex;
        margin-left: 20%;
    }
    .mobile-navbar .menu-content .menu-container .menu-icons {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 35px;
    }
    .menu-icons p {
        width: 50px;
        height: 50px;
        font-size: 40px;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        border: 1px solid white;
        border-radius: 10px;
        padding-bottom: 10px;
    }
    .btns .register-popup {
        position: absolute;
        background-color: #212121;
        top: 20px;
        border: 2px solid white;
        border-radius: 25px;
        width: 55%;
        z-index: 999;
    }
    #panel1a-header {
        background-color:black;
        color: white;
    }
    #heading {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: var(--font-family);
        font-size:20px;
        font-weight:500; 
    }
}
@media (max-width: 768px) {
    .navbar {
        padding: 3px 25px;
    }
    .navbar .mobile-navbar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    .mobile-navbar .logo {
        width: 70px;
        height: 70px;
    }
    .mobile-navbar .logo img {
        width: 100%;
        height: 100%;
    }
    .mobile-navbar .menu-content .menu-container .courses {
        padding-left: 15px;
    }
    .btns .register-popup {
        position: absolute;
        top: 0;
        width: 65%;
    }
    .btns .register-popup .popup-content {
        background-color: #212121;
        color: white;
        border-radius: 25px;
    }
    .btns .register-popup .popup-content .close-icon {
        position: absolute;
        top: 30px;
        right: 20px;
        border: none;
        background: none;
        color: #e1d33e;
    }
    .btns .register-popup .popup-content .form-container .buttons button {
        font-size: 15px;
        font-weight: 100;
    }
    .btns .form-container form input {
        font-size: 15px;
    }
    .btns .forgot-password {
        text-underline-offset: 8px;
        font-weight: 100;
        font-size: 13px;
    }
}
@media (max-width:550px) {

    .btns .register-popup {
        position: absolute;
        top: 0;
        width: 90%;
        margin: 0 20px;
    }
    .btns .register-popup .popup-content {
        background-color: #212121;
        color: white;
        border-radius: 25px;
    }
}