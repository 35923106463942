#event {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#eventP {
    background-color: #1C1C1C;
    font-size: 18px;
    border-radius: 25px;
    padding: 1rem;
    width: 50%;
}
@media (max-width: 1439px) {
    #eventP {
        width: 100%;
    }
}